<template>
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M5.33333 5.33333C5.33333 4.59583 5.92917 4 6.66667 4H17.3333C18.0708 4 18.6667 4.59583 18.6667 5.33333V10.6667C18.6667 11.4042 18.0708 12 17.3333 12H6.66667C5.92917 12 5.33333 11.4042 5.33333 10.6667V5.33333ZM16.6667 6H7.33333V10H16.6667V6ZM24 2.66667V6C22.8958 6 22 6.89583 22 8C22 9.10417 22.8958 10 24 10V13.3333C24 14.8042 22.8042 16 21.3333 16H2.66667C1.19375 16 0 14.8042 0 13.3333V10C1.10458 10 2 9.10417 2 8C2 6.89583 1.10458 6 0 6V2.66667C0 1.19375 1.19375 0 2.66667 0H21.3333C22.8042 0 24 1.19375 24 2.66667ZM2 4.53333C3.19542 5.225 4 6.52083 4 8C4 9.47917 3.19542 10.775 2 11.4667V13.3333C2 13.7 2.29833 14 2.66667 14H21.3333C21.7 14 22 13.7 22 13.3333V11.4667C20.8042 10.775 20 9.47917 20 8C20 6.52083 20.8042 5.225 22 4.53333V2.66667C22 2.3 21.7 2 21.3333 2H2.66667C2.29833 2 2 2.3 2 2.66667V4.53333Z"
    />
  </svg>
</template>
